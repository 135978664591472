@import "assets/styles/cssImports.scss";

.laboratory-analysis {
  margin-bottom: rem(70);
  &__breadcrumb {
    margin-top: rem(160);
  }

  .content-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    ul {
      margin-left:20px;
    }
    .video-preview {
      width: 100%;
      border-radius: 24px;
      margin-bottom: 22px;
      margin-top: 40px;
    }

    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 110px;
      width: 110px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 60px;
      background-color: rgba(255, 255, 255, 0.4);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(20px);
      outline: none;
      border: 1px solid white;
      pointer-events: none;
    }

    .map-container {
      height: 346px;
      width: 100%;
      position: relative;

      &__title {
        background-color: #ffffff;
        box-shadow: 0px 6px 12px #00000014;
        border-radius: 15px;
        padding: 16px 18px;
        color: #303134;
        font-size: 18px;
        font-weight: bold;
        width: fit-content;
        position: absolute;
        top: 0;
        left: 5px;
        top: 8px;
      }
    }

    .analysis-name {
      margin-top: 32px;
      margin-bottom: 39px;
      font-weight: bold;
      text-transform: uppercase;
      color: #303134;
      font-size: 42px;
    }

    .analysis-tag-container {
      display: flex;
      gap: 12px;
      margin-bottom: 40px;

      .analysis-tag {
        background-color: #f3f3f8;
        border-radius: 24px;
        width: fit-content;
        padding: 7px 16px;
        color: #6a6d9a;
      }
    }

    .text-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 32px;

      .heading {
        color: #303134;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 18px;
      }

      .description {
        font-size: 18px;
        letter-spacing: 0px;
        color: #303134;
        line-height: 28px;
      }
    }
  }

  @media (max-width: 1100px) {
    &__breadcrumb {
      margin-top: rem(100);
    }
  }
}
